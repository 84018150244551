import '../css/aboutme.css'

import NavbarMainPage from "../components/navbars/navbar";
import {AboutMeContent} from "../components/aboutMePage/content";
import WebsiteFooter from '../components/footer/footer';

const AboutMePage = () => {
	return(
		<div>
			<NavbarMainPage/>
			<AboutMeContent/>
			<WebsiteFooter/>
		</div>
	);
}

export default AboutMePage;