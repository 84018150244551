import '../css/website.css'
import '../css/main.css'
import { useEffect } from 'react';
import NavbarMainPage from '../components/navbars/navbar';
import WebsiteFooter from '../components/footer/footer';
import { Carousel, Content } from '../components/mainPage/content';


const MainPage = () => {

	useEffect(() => {
		const slides = document.getElementsByClassName("index-slides");

		Carousel(slides);
		const intervalId = setInterval(Carousel, 6000, slides);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return(
		<div>
			<NavbarMainPage/>
			<Content/>
			<WebsiteFooter/>
		</div>
	);
}

export default MainPage;