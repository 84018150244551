import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4';

import '../../css/main.css'
import '../../css/contact.css'

import i1 from '../../img/Slideshow/i1.jpg'
import map from '../../img/contact/map-template-4x3.jpg'
import { Console } from 'console';

export function ContactContent() {
    const [phoneActive, setPhoneActive] = useState(false);
    const [emailActive, setEmailActive] = useState(false);

    const handleClick = (id: string) => {
        if (id === "e-mail") {
            setEmailActive(true);
            setTimeout(() => setEmailActive(false), 200)
        }
        else if (id === "phone-number") {
            setPhoneActive(true)
            setTimeout(() => setPhoneActive(false), 200)
        }
        else {
            console.error("There is no ID called " + id)
        }
    };
    const handleCopy = (copyText: string, id: string) => {
        ReactGA.event({
            category: 'Button',
            action: 'Clicked ' + id,
            label: id,
        });
        navigator.clipboard.writeText(copyText)
        handleClick(id)

    }

    return (
        <div>
            <nav id="main-nav"></nav>
            <div className="nav-dummy"></div>

            <div className="contact-background">
                <img src={i1} alt="bg" />
                <div className="background-filter"></div>
            </div>
            <div className="contact-main">
                <div className="contact-title">
                    <h1>KONTAKT</h1>
                </div>
                <div className="email contact-element">
                    <h3>E-MAIL:</h3>
                    <button onClick={() => handleCopy("fizjo.wolny@gmail.com", "e-mail")} style={{ backgroundColor: emailActive ? "rgba(0, 0, 0, .2)" : "transparent" }}>fizjo.wolny@gmail.com</button>
                </div>
                <div className="phone contact-element">
                    <h3>TELEFON:</h3>
                    <button onClick={() => handleCopy("+48 602 276 645", "phone-number")} style={{ backgroundColor: phoneActive ? "rgba(0, 0, 0, .2)" : "transparent" }}>+48 602 276 645</button>
                </div>

                <div className="cities contact-element">
                    <h3>OBSZAR WYKONYWANYCH USUG:</h3>
                    <ul className="cities-list">
                        <li>Ornontowice</li>
                        <li>Orzesze</li>
                        <li>Gierałtowice</li>
                        <li>Chudów</li>
                        <li>Knurów</li>
                        <li>Paniowy</li>
                        <li>Paniówki</li>
                        <li>Czerwionka-Leszczyny</li>
                        <li>Zabrze</li>
                        <li>Gliwice</li>
                        <li>Mikołów</li>
                        <li>miejscowości ościenne</li>
                    </ul>
                </div>
                <div className="map">
                    <img src={map} alt="map" />
                </div>
            </div>
        </div >
    );

}