import { ContactContent } from "../components/contact/content";
import WebsiteFooter from "../components/footer/footer";
import NavbarMainPage from "../components/navbars/navbar";

const ContactPage = () => {
	return(
		<div>
			<NavbarMainPage/>
			<ContactContent/>
			<WebsiteFooter/>
		</div>
	);
}

export default ContactPage;