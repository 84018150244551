import { useNavigate } from 'react-router-dom';
import '../../css/nav.css';
import logo from '../../img/ico/logov8.png'
import menuButton from '../../img/menuButton.png'
import { Offcanvas, Stack } from 'react-bootstrap';
import { useState } from 'react';
import ReactGA from 'react-ga4';

const NavbarData = {
	logo: "Logo",
	contact: "Kontakt",
	specialisations: "Specjalizacje",
	aboutMe: "O mnie"
}

//Navigation bar on the top of the screen
function NavbarMainPage() {
	const navigate = useNavigate();
	const [show, setShow] = useState(false);
	const handleClick = (labelTag: string, path: string) => {
		ReactGA.event({
			category: 'Button',
			action: 'Clicked ' + labelTag,
			label: labelTag,
		});

		navigate(path);

	};
	return (
		<div>
			<nav className="main-nav">
				<div className="nav-container">
					<div className="nav-left">
						<button onClick={() => handleClick("Strona główna", '/')}>
							<img src={logo} alt='logo' />
						</button>
					</div>
					<div className="nav-right">
						<button onClick={() => handleClick("O mnie", '/about')}>
							{NavbarData.aboutMe}
						</button>
						<button onClick={() => handleClick("Kontakt", '/contact')}>
							{NavbarData.contact}
						</button>

					</div>
					<div className="nav-right-mobile nav-right">
						<button onClick={() => setShow(true)} >
							<img src={menuButton} alt='menuButton' style={{ width: "4vh" }} />
						</button>
					</div>
				</div>
			</nav>
			<Offcanvas show={show} onHide={() => setShow(false)} placement='end' className='offcanvas-side-menu'>
				<Offcanvas.Header closeButton style={{ paddingRight: "10%", paddingTop: "10%", paddingBottom: "1%" }}>
					<Offcanvas.Title></Offcanvas.Title>
				</Offcanvas.Header>
				<Offcanvas.Body className='offcanvas-body-side-manu'>
					<Stack direction='vertical' className='stack-side-menu'>
						<div className='line-mid-side-menu'></div>

						<button onClick={() => handleClick("O mnie", '/about')}>
							{NavbarData.aboutMe}
						</button>
						<div className='line-mid-side-menu'></div>
						<button onClick={() => handleClick("Kontakt", '/contact')}>
							{NavbarData.contact}
						</button>

						<div className='line-mid-side-menu'></div>
					</Stack>
					<div className='line-mid-side-menu'></div>
					<button id='copyrights-side-menu'>AMJ © Copyright. All rights reserved. </button>
				</Offcanvas.Body>
			</Offcanvas>
		</div>

	);
}

export default NavbarMainPage;