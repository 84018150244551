import '../../css/footer.css'
import '../../css/website.css'

export const Footer = {
    logo: "My Logo",
    placeholder1: "Footer Placeholder",
    placeholder2: "Footer Placeholder",
    aboutMe: "About me",
    copyright: "©Copyright. All rights reserved."
}

//Hyperlinks to a href needs to be added
function WebsiteFooter() {
    return (
        <footer id="footer">
            <div className="footer-container">
                <div className="footer-top">
                    <div className='footer-top-left'>
                        <button className="footer-title" >Kontakt</button>
                        <button className='footer-phone'>Telefon: +48 602 276 645</button>
                        <button>E-mail: fizjo.wolny@gmail.com</button>
                    </div>
                    <div className='footer-top-mid'>
                        <button className="footer-title">Co leczę</button>
                        <ul>
                            <li>Rehabilitacja neurologiczna </li>
                            <li>Rehabilitacja ortopedyczna </li>
                            <li>Rehabilitacja onkologiczna </li>
                            <li>Leczenie i profilaktyka bólów kręgosłupa </li>
                        </ul>
                    </div>
                    <div className='footer-top-right'>
                        <button className="footer-title">Znajdziesz mnie też na</button>
                        <button>znanylekarz.pl</button>
                    </div>
                </div>
                <div className="line-mid-footer"></div>
                <div className="footer-bottom">
                    <button className="copyright">AMJ © Copyright. All rights reserved.</button>
                </div>
            </div>
        </footer>
    )
}

export default WebsiteFooter;