import { useNavigate } from 'react-router-dom'
import '../../css/main.css'
import ReactGA from 'react-ga4';

import backgroundImage1 from '../../img/Slideshow/i1.jpg'
import backgroundImage2 from '../../img/Slideshow/i2.jpg'
import backgroundImage3 from '../../img/Slideshow/i3.jpg'
import backgroundImage4 from '../../img/Slideshow/i4.jpg'
import { useEffect } from 'react';

export const ContentData = {
	title1: "Arkadiusz Wolny",
	tilte2: "Co leczę",
	description1: "Jestem absolwentem dziennych studiów magisterskich na Akademii Wychowania Fizycznego w Katowicach na kierunku Fizjoterapia. Swoją praktykę fizjoterapeutyczną w pracy z pacjentem rozwijam od 2000r., jak również uczestnicząc w licznych kursach i szkoleniach podyplomowych.",
	description2_1: "Rehabilitacja neurologiczna",
	description2_2: "Rehabilitacja ortopedyczna",
	description2_3: "Rehabilitacja onkologiczna",
	description2_4: "Leczenie i profilaktyka bólów kręgosłupa",
	button: "Zobacz więcej"
}

var myIndex = 0;

// This exported function takes in a collection of HTML elements representing the slides of a carousel
export function Carousel(slides: HTMLCollectionOf<Element>) {
	for (const slide of slides) {
		slide.classList.add("index-hidden");
		slide.classList.remove("index-visible");
	}

	myIndex = (myIndex + 1) % slides.length;
	// Display the next slide by adding the "index-visible" class and removing the "index-hidden" class
	slides[myIndex].classList.add("index-visible");
	slides[myIndex].classList.remove("index-hidden");
}

export function Content() {
	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);
	const handleClick = (labelTag: string, path: string) => {
		ReactGA.event({
			category: 'Button',
			action: 'Clicked ' + labelTag,
			label: labelTag,
		});
		navigate(path);
	};
	return (
		<div>
			<div className="top-image-container">
				<img className="index-image index-slides" src={backgroundImage1} alt='slide1' />
				<img className="index-image index-slides" src={backgroundImage2} alt='slide2' />
				<img className="index-image index-slides" src={backgroundImage3} alt='slide3' />
				<img className="index-image index-slides" src={backgroundImage4} alt='slide4' />
			</div>
			<div className="text-containers-grid">
				<div className="main-lockup">
					<h1 className="index-title">Arkadiusz  Wolny</h1>
					<div className="index-description"><p>{ContentData.description1}</p>
						<button onClick={() => handleClick("O mnie", '/about')} className="main-lockup-button">{ContentData.button}</button>
					</div>
				</div>
				<div className="index-lockup">
					<h1 className="index-title">{ContentData.tilte2}</h1>
					<div className="index-description">

						<ul>
							<li>{ContentData.description2_1}</li>
							<li>{ContentData.description2_2}</li>
							<li>{ContentData.description2_3}</li>
							<li>{ContentData.description2_4}</li>
						</ul>

						<button onClick={() => handleClick("O mnie", '/about')} className="main-lockup-button">{ContentData.button}</button>
					</div>
				</div>
				<div className="index-lockup">
					<h1 className="index-title">Kontakt</h1>
					<div className="index-description">
						<p>Telefon: 602 276 645 <br /> Email: fizjo.wolny@gmail.com</p>
						<button onClick={() => handleClick("Kontakt", '/contact')} className="main-lockup-button">Umów wizytę</button>
					</div>
				</div>
			</div>
		</div>
	);

}

