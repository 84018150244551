import { Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import ContactPage from './pages/ContactPage';
import AboutMePage from './pages/AboutMePage';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';

function App() {
	useEffect(() => {
		ReactGA.initialize('G-QFXJJDMBVF');
		ReactGA.send({ hitType: "pageview", page: "/" });
	}, []);
	return (
		<div>
			<Routes>
				<Route path='/' element={<MainPage />} />
				<Route path='/contact' element={<ContactPage />} />
				<Route path='/about' element={<AboutMePage />} />
			</Routes>
		</div>
	);
}

export default App;
