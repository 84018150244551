
import aboutMeTransparent from '../../img/aboutme/about-me-transparent.png'
import aboutMeMobile from '../../img/aboutme/about-me-mobile.png'
import backgroundImage1 from '../../img/aboutme/test-0.png'
import backgroundImage2 from '../../img/Slideshow/i1.jpg'

export const ContentData = {
    title: "Arkadiusz Wolny",
    title2: "nr Prawa Wykonywania Zawodu Fizjoterapeuty: 9325",
    description_1: "Jestem absolwentem dziennych studiów magisterskich Akademii Wychowania Fizycznego w Katowicach na kierunku Fizjoterapia. Swoją praktykę fizjoterapeutyczną w pracy z pacjentem rozwijam od 2000r., jak również uczestnicząc w licznych kursach i szkoleniach podyplomowych  między innymi z terapii manualnej, PNF (proprioceptywne nerwowo-mięśniowe torowanie ruchu) czy odnowy biologicznej.",
    description_2: "W swoim postępowaniu terapeutycznym opieram się na nowoczesnych badaniach klinicznych w dziedzinie rehabilitacji, ortopedii i neurologii co w połączeniu z wieloletnim doświadczeniem czyni moją działalność leczniczą skuteczną i przede wszystkim bezpieczną. W pracy z pacjentem kieruję się zasadą indywidualnego doboru metod i  programu terapii, bazując na fizycznych i mentalnych zasobach pacjentów, maksymalnie wykorzystując tkwiący w nich potencjał, który umożliwia jak najszybszy powrót do sprawności i zdrowia.",
    description_3: "W życiu prywatnym jestem osobą aktywną fizycznie  uprawiającą liczne dyscypliny sportowe, poznając tym samym ich wpływ na nasz narząd ruchu. Interesuję się również psychologią człowieka, co pozwala mi zrozumieć, że właściwe relacje z pacjentem oraz jego świadomość i psychika mają kluczowy wpływ na proces zdrowienia.",
    description_4: "Ze względu na charakter schorzeń większości moich pacjentów skoncentrowałem się wyłącznie na wizytach w domu pacjenta, mając również na uwadze fakt, że środowisko domowe znacznie ułatwia powrót do samodzielności i funkcjonowania w najbliższym otoczeniu.",
    button: "Co leczę"

}

//Background mp4 video does not work properly
export function AboutMeContent() {
    const scrollLenght = 2.4;
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight / scrollLenght,
            behavior: 'smooth',
        });
    };
    return (
        <div>
            <div className='about-me-mobile-container'>
                <div className="about-me-image-container-mobile">
                    <img src={aboutMeMobile}
                        /*src={aboutMeTransparent} src={aboutMeMobile}*/
                        alt='profile' />
                </div>
                <div className='about-me-text-container-mobile'>
                    <div>
                        <h1 className="about-me-name">{ContentData.title}</h1>
                        <h2 className="about-me-name">{ContentData.title2}</h2>
                    </div>
                    <div className="about-me-description-1">
                        <p>{ContentData.description_1}</p>
                        <p>{ContentData.description_2}</p>
                        <p>{ContentData.description_3}</p>
                        <p>{ContentData.description_4}</p>
                    </div>
                </div>
            </div>

            <div className="about-me-main-container">
                <div className="about-me-background">
                    <img src={backgroundImage1} alt='bg1' />
                    <div className="background-filter"></div>
                </div>

                <div className="about-me-text-wrap">
                    <div className="about-me-text-container">
                        <h1 className="about-me-name">{ContentData.title}</h1>
                        <h2 className="about-me-name">{ContentData.title2}</h2>
                        <div>
                            <p>{ContentData.description_1}</p>
                            <p>{ContentData.description_2}</p>
                            <p>{ContentData.description_3}</p>
                            <p>{ContentData.description_4}</p>
                        </div>
                        <button className="main-lockup-button" onClick={scrollToBottom}>Zobacz więcej</button>
                    </div>
                </div>
                <div className="about-me-image-container">
                    <img src={aboutMeTransparent} alt='profile' />
                </div>
            </div>
            <div className="paralax-container">
                <div className="paralax-text-container">
                    <h2>
                        {ContentData.button}
                    </h2>
                </div>

                <img src={backgroundImage2} alt='bg2' />
                <div className="about-me-more-container">
                    <div className="about-me-more-element">
                        <h2>1. Rehabilitacja neurologiczna:</h2>
                        <div>
                            - po udarach mózgu (wylewy, zatory)<br />
                            - po urazach czaszkowo-mózgowych<br />
                            - w chorobach i uszkodzeniach rdzenia kręgowego<br />
                            - w chorobach i uszkodzeniach nerwów obwodowych<br />
                            - w wyniku choroby Parkinsona<br />
                            - w wyniku stwardnienia rozsianego<br />
                            - w wyniku rdzeniowego zaniku mięśni (SMA) <br />
                            - stwardnienie zanikowe boczne (SLA) <br />
                            - w wyniku innych chorób neurologicznych mających wpływ na narząd ruchu <br />
                        </div>
                    </div>
                    <div className="about-me-more-element">
                        <h2>2. Rehabilitacja ortopedyczna:</h2>
                        <div>
                            - po złamaniach, zwichnięciach, skręceniach stawowych<br />
                            - w zmianach zwyrodnieniowych stawów<br />
                            - po zabiegach operacyjnych narządu ruchu<br />
                            - w endoprotezoplastyce stawów<br />
                            - w rekonstrukcji więzadeł i ścięgien<br />
                            - w zespołach bólowych tkanek miękkich<br />
                            - korekta wad postawy w wieku rozwojowym<br />
                            - po amputacjach<br />
                            - przygotowanie do zabiegów i operacji ortopedycznych<br />

                        </div>
                    </div>
                    <div className="about-me-more-element">
                        <h2>3. Profilaktyka bólów kręgosłupa</h2>
                        <div>
                            - w problemach onkologicznych mających wpływ na narząd ruchu<br />
                            - po uciążliwym leczeniu onkologicznym mającym wpływ na sferę ruchową<br />
                        </div>
                    </div>

                    <div className="about-me-more-element">
                        <h2>4. Leczenie i profilaktyka bólów kręgosłupa w wyniku:</h2>
                        <div>
                            - przepukliny jądra miażdżystego (dyskopatia)<br />
                            - kręgozmyku<br />
                            - po zabiegach neurochirurgicznych w okolicy kręgosłupa
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}